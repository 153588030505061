// extracted by mini-css-extract-plugin
export const text = "text-module--text--2LcWS";
export const xSmall = "text-module--x-small--3ZAwi";
export const small = "text-module--small--32mPJ";
export const medium = "text-module--medium--3_Svn";
export const large = "text-module--large--aW0CF";
export const xLarge = "text-module--x-large--2idS-";
export const xxLarge = "text-module--xx-large--8Jtli";
export const weightRegular = "text-module--weight-regular--1UzL7";
export const weightMedium = "text-module--weight-medium--2lHH6";
export const weightBold = "text-module--weight-bold--3oqpB";
export const uppercase = "text-module--uppercase--24SjT";
export const none = "text-module--none--28TEh";