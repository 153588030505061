import classNames from "classnames";
import propTypes from "prop-types";
import React from "react";

import * as styles from "./button.module.scss";

const Button = ({
  className,
  children,
  href,
  appearance,
  isSelected,
  size,
  ...rest
}) => {
  const Tag = href ? "a" : "button";
  return (
    <Tag
      href={href}
      className={classNames(
        styles.button,
        styles[size],
        styles[appearance],
        { [styles.isSelected]: isSelected },
        className
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

Button.propTypes = {
  appearance: propTypes.oneOf(["solid", "ghost", "bare"]),
};

Button.defaultProps = {
  appearance: "solid",
  size: "medium",
};

export default Button;
