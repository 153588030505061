import React, { useEffect, useState } from "react";
import classnames from "classnames";
import MotionLogo from "../../components/motion-logo";
import Button from "../../components/button";
import * as styles from "./header.module.scss";
import Text from "../../components/text";

const Header = (props) => {
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [isLogoPainted, setIsLogoPainted] = useState(props.isVisible);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    setIsVisible(props.isVisible || window.scrollY > window.outerHeight * 0.4);
    setIsLogoPainted((isLogoPainted) => {
      return isLogoPainted || window.scrollY > window.outerHeight * 0.4;
    });
  }

  function handleClick(event) {
    const marker = document.querySelector(
      `[name="${event.currentTarget.getAttribute("href").slice(2)}"]`
    );

    if (marker) {
      event.preventDefault();
      marker.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  return (
    <header
      className={classnames(styles.header, {
        [`${styles.isVisible}`]: isVisible,
      })}
    >
      <Text size='small' weight='medium' className={classnames(styles.date)}>
        Hallein – 24th July 2021
      </Text>
      <a href='/' aria-hidden className={classnames(styles.logo)}>
        <MotionLogo isPainted={isLogoPainted} />
      </a>
      <Button
        href='/#register'
        appearance='bare'
        size='small'
        onClick={handleClick}
        className={classnames(styles.signup)}
      >
        Register now
      </Button>
    </header>
  );
};

export default Header;
