import { graphql, useStaticQuery } from "gatsby";
import Helmet from "react-helmet";
import React from "react";
import Frame from "../components/frame";
import Header from "../content/header";

const Layout = ({ children }) => {
  const {
    contentfulMetaData: {
      description: { description },
      title,
      image,
      url,
    },
  } = useStaticQuery(graphql`
    query MyQuery {
      contentfulMetaData {
        description {
          description
        }
        url
        title
        image {
          fluid(maxWidth: 1200, quality: 80) {
            src
          }
        }
      }
    }
  `);
  return (
    <>
      <div data-name='check-this'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{title}</title>

          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0'
          />
          <meta name='description' content={description} />

          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href='/apple-touch-icon.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href='/favicon-32x32.png'
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href='/favicon-16x16.png'
          />
          <link rel='manifest' href='/site.webmanifest' />
          <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
          <meta name='msapplication-TileColor' content='#00a300' />
          <meta name='theme-color' content='#ffffff'></meta>

          <title>{title}</title>
          <meta name='title' content={title} />
          <meta name='description' content={description} />

          <meta property='og:type' content='website' />
          <meta property='og:url' content={url} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta property='og:image' content={`https:${image.fluid.src}`} />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:url' content={url} />
          <meta name='twitter:title' content={title} />
          <meta name='twitter:description' content={description} />
          <meta
            name='twitter:image'
            content={`https:${image.fluid.src}`}
          ></meta>
          <script type='application/ld+json'>
            {`{
  "@context": "https://schema.org",
  "@type": "Event",
  "location": {
    "@type": "Place",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Hallein",
      "postalCode": "5400",
      "streetAddress": "Metzgergasse 7"
    },
    "name": "Pan Café"
  },
  "name": "Schuss & Schotter",
  "image": ["https:${image.fluid.src}"],
  "startDate": "2021-07-24T"
}`}
          </script>
        </Helmet>
        <Frame />
        {children}
      </div>
      <script
        data-goatcounter='https://schuss-schotter.goatcounter.com/count'
        async
        src='//gc.zgo.at/count.js'
      ></script>
    </>
  );
};

export default Layout;
