import React from "react";
import propTypes from "prop-types";
import cn from "classnames";
import * as styles from "./text.module.scss";
import camelcase from "../../utils/camelcase";

const Text = ({
  children,
  uppercase,
  size,
  weight,
  tag,
  className,
  spacing,
  ...others
}) => {
  const Tag = tag;
  return (
    <Tag
      className={cn(
        styles.text,
        styles[spacing],
        { [styles.uppercase]: uppercase },
        styles[camelcase(size)],
        styles[camelcase(`weight-${weight}`)],
        className
      )}
      {...others}
    >
      {children}
    </Tag>
  );
};

Text.propTypes = {
  size: propTypes.oneOf([
    "x-small",
    "small",
    "medium",
    "large",
    "x-large",
    "xx-large",
  ]),
  weight: propTypes.oneOf(["regular", "medium", "bold"]),
};

Text.defaultProps = {
  tag: "p",
  size: "medium",
  weight: "regular",
  uppercase: false,
  spacing: "none",
};

export default Text;
