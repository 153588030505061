import React from 'react';
import cn from 'classnames';
import * as styles from './frame.module.scss';

const Frame = () => {

    return (
        <div className={cn(styles.frame)} />
    )
}

export default Frame;